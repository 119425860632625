<template>
  <div class="custom-container">
    <el-row>
      <el-page-header
        @back="goBack()"
        :content="isEdit ? '编辑加工单' : '新增加工单'"
      ></el-page-header>
    </el-row>

    <el-form :model="form" :rules="rules" ref="ruleForm">
      <h4>基本信息</h4>
      <el-row>
        <el-col :span="6">
          <el-form-item
            label="销售单号"
            prop="salesOrderCode"
            label-width="80px"
            :rules="[{ required: !isEdit, message: '请输入', trigger: 'blur' }]"
          >
            <FuzzyMatching
              ref="fuzzyMatching"
              :disabled="isEdit || isDisOrderCode"
              :defaultOrderCode="defaultOrderCode"
              @fuzzy-choose="fuzzyChoose"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="供应商" prop="supplierId" label-width="100px">
            <span v-if="isEdit">
              <el-input v-model="form.supplierName" :disabled="true"></el-input>
            </span>
            <el-select
              v-else
              v-model="form.supplierId"
              @change="supplierChange"
              filterable
              clearable
            >
              <el-option
                v-for="item in defaultSupplierList"
                :label="item.supplierName"
                :value="item.supplierId"
                :key="item.supplierId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="成品入库"
            prop="inWarehouseId"
            label-width="100px"
          >
            <el-select
              v-model="form.inWarehouseId"
              @change="inWarehouseChange"
              placeholder="请选择"
            >
              <el-option
                v-for="item in warehouses"
                :key="item.warehouseId"
                :value="item.warehouseId"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <h4>加工产品</h4>
      <el-table
        :data="form.list"
        border
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        class="mt20 dyn-table"
        max-height="495px"
      >
        <el-table-column label="产品信息" width="240">
          <template slot-scope="scope">
            <div class="label-text">
              <p>产品编码：{{ scope.row.skuId }}</p>
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.productCn"
                placement="top-start"
              >
                <p class="ell">产品名称：{{ scope.row.productCn }}</p>
              </el-tooltip>
              <p>
                客户代表：{{
                  form.businessUserName + '(' + form.businessUserNameEn + ')'
                }}
              </p>
            </div>
          </template>
        </el-table-column>
        <!-- 计划装运日期/客户交期 -->
        <el-table-column align="center" width="260" label="计划装运日期/客户交期">
          <template slot-scope="{ row }">
            <div style="text-align:left">
              <span>计划装运日期</span>
              <el-tooltip placement="top">
                <div slot="content">订单预计交付给国际物流或国际快递的<br/>日期，并以装运地的时区时间为准。</div>
                <i class="el-icon-question"/>
              </el-tooltip>
              <span>：</span>
              <span>{{ row.productExtend && row.productExtend.planShippingDate ? row.productExtend.planShippingDate : '--' }}</span>
            </div>
            <div style="text-align:left">
              <span>客户交期</span>
              <el-tooltip placement="top">
                <div slot="content">
                  1、订单交付到客户指定收货地点的约定日期，并以交货<br/>
                  地的时区时间为准，需要全力确保与客户约定的交期。<br/>
                  2、「Firm 交期」指的是明确的客户交期，需要严格地<br/>
                  满足此交期，否则会面临更加严重的违约责任。
                </div>
                <i class="el-icon-question"/>
              </el-tooltip>
              <span>：</span>
              <span>{{ row.productExtend && row.productExtend.customerDeliveryDate ? row.productExtend.customerDeliveryDate : '--' }}</span>
              <el-tag v-if="row.productExtend && row.productExtend.firmFlag == 1" size="mini" type="warning" effect="dark" style="margin-left:5px">Firm 交期</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="产品颜色"
          width="150"
          label-class-name="required-th"
        >

          <template slot-scope="scope">
            <el-form-item
              :prop="'list.' + [scope.$index] + '.productSpecs'"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                maxlength="50"
                v-model="form.list[scope.$index].productSpecs"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column
          label="订单数量"
          width="140"
          label-class-name="required-th"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'list.' + [scope.$index] + '.processNum'"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'blur',
                },
                {
                  pattern: /^[0-9]*[1-9][0-9]*$/,
                  message: '请输入>0的整数',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                maxlength="10"
                :disabled="isEdit"
                type="number"
                v-model="form.list[scope.$index].processNum"
                @input="calcAmount(scope.$index)"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="留样数量" width="140">
          <template slot-scope="scope">
            <el-form-item
              :prop="'list.' + [scope.$index] + '.sampleNum'"
              :rules="[
                {
                  required: false,
                  message: '请输入',
                  trigger: 'blur',
                },
                {
                  pattern: /^[0-9]+?$/,
                  message: '请输入>=0的整数',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                maxlength="10"
                :disabled="isEdit"
                type="number"
                v-model="form.list[scope.$index].sampleNum"
                @input="calcAmount(scope.$index)"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="效果图" width="120">
          <template slot-scope="scope">
            <!-- <erp-image :imgs="scope.row.productImage"></erp-image> -->
            <erp-image
              :imgs="
                form.list[scope.$index].printWay === '无需印刷'
                  ? scope.row.rendingsUrl || scope.row.imgMain
                  : scope.row.rendingsUrl
              "
            ></erp-image>
          </template>
        </el-table-column>
        <el-table-column
          label="印刷方式"
          width="150"
          label-class-name="required-th"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'list.' + [scope.$index] + '.printWay'"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                  trigger: 'change',
                },
              ]"
            >
              <el-select
                v-model="form.list[scope.$index].printWay"
                placeholder="请选择"
                @change="handlePrintWayChange"
              >
                <el-option
                  v-for="(item, i) in printingWaySelect"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="印刷颜色"
          width="150"
          label-class-name="required-th"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'list.' + [scope.$index] + '.printColor'"
              :rules="[
                {
                  required:
                    form.list[scope.$index].printWay != '无需印刷'
                      ? true
                      : false,
                  message: '请输入',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="textarea"
                v-model="form.list[scope.$index].printColor"
                maxlength="500"
                show-word-limit
                :rows="2"
                placeholder="500字符以内"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="印刷尺寸"
          width="200"
          label-class-name="required-th"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'list.' + [scope.$index] + '.printSize'"
              :rules="[
                {
                  required:
                    form.list[scope.$index].printWay != '无需印刷'
                      ? true
                      : false,
                  message: '请输入',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="textarea"
                maxlength="500"
                show-word-limit
                :rows="2"
                v-model="form.list[scope.$index].printSize"
                placeholder="500字符以内"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="printDesc" label="印刷描述" width="200">
          <template slot-scope="scope">
            <el-form-item>
              <el-input
                type="textarea"
                maxlength="500"
                show-word-limit
                :rows="2"
                v-model="form.list[scope.$index].printDesc"
                placeholder="500字符以内"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="packaging" label="包装要求" width="200">
          <template slot-scope="scope">
            <el-form-item>
              <el-input
                type="textarea"
                maxlength="500"
                show-word-limit
                :rows="2"
                v-model="form.list[scope.$index].packaging"
                placeholder="500字符以内"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="urgent" label="是否急单" width="150">
          <template slot-scope="scope">
            <el-select
              v-model="form.list[scope.$index].urgent"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, i) in whetherList"
                :key="i"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="innerRemark" label="内部备注" width="180">
          <template slot-scope="scope">
            <div v-if="scope.row.innerRemark">
              <el-tooltip :content="scope.row.innerRemark" placement="top">
                <div class="limit-text c-666">
                  {{ scope.row.innerRemark }}
                </div>
              </el-tooltip>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="expectDeliveryDate"
          label="工厂交期"
          width="165"
          fixed="right"
          label-class-name="required-th"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'list.' + [scope.$index] + '.expectDeliveryDate'"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                  trigger: 'change',
                },
              ]"
            >
              <el-date-picker
                v-model="form.list[scope.$index].expectDeliveryDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column v-if="!isEdit" label="操作" fixed="right" width="80">
          <template slot-scope="scope">
            <el-button
              class="icon-r"
              icon="el-icon-delete"
              circle
              @click="del(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right mt10">
        生产总数：
        <span class="red">{{ amount }}</span>
      </div>

      <h4>物料信息</h4>
      <el-table
        :data="form.list"
        border
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        class="mt20 input-table"
        max-height="380px"
      >
        <el-table-column
          label="物料编码"
          prop="skuId"
          width="140"
        ></el-table-column>
        <el-table-column
          label="物料名称"
          prop="productCn"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="ell">
              {{ scope.row.productCn }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="规格" prop="productSpecs" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="ell">{{ scope.row.productSpecs }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!isEdit"
          label="印刷方式"
          prop="printWay"
        ></el-table-column>
        <el-table-column label-class-name="required-th">
          <template slot="header">
            损耗率(%)
            <el-popover
              placement="top"
              width="160"
              v-model="multiSetVisible"
              v-if="!isEdit"
            >
              <p class="text-center">批量设置</p>
              <el-input-number
                class="mt10"
                v-model="attritionRate"
                controls-position="right"
                :min="0"
                :max="100"
                :precision="2"
              ></el-input-number>
              <div class="text-right mt10">
                <el-button
                  size="mini"
                  type="text"
                  @click="multiSetVisible = false"
                >
                  取消
                </el-button>
                <el-button type="primary" size="mini" @click="attritionRateSet">
                  确定
                </el-button>
              </div>
              <i slot="reference" class="el-icon-edit multi-icon"></i>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="'list.' + [scope.$index] + '.loseRatio'"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['blur', 'change'],
                },
                {
                  pattern: /^\d+(\.\d{1,2})?$/,
                  message: '>=0最多两位小数',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="number"
                maxlength="10"
                v-model="form.list[scope.$index].loseRatio"
                :disabled="isEdit"
                @blur="calcAmount(scope.$index, 'loseRatio')"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!isEdit"
          label="申请出库数量"
          prop="applyNum"
        ></el-table-column>
        <el-table-column v-if="!isEdit" label="可用库存">
          <template slot-scope="scope">
            <p v-for="(item, i) in scope.row.warehouses" :key="i">
              {{ item.warehouseName }}：{{ item.num }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="出库仓库" label-class-name="required-th">
          <template slot-scope="scope">
            <div v-if="isEdit">{{ scope.row.warehouseOutName }}</div>
            <el-form-item
              v-else
              :prop="'list.' + [scope.$index] + '.warehouseId'"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'change',
                },
              ]"
            >
              <el-select
                v-model="form.list[scope.$index].warehouseId"
                :disabled="isEdit"
                @change="warehouseChange($event, scope.$index)"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, i) in scope.row.warehouses"
                  :key="i"
                  :label="item.warehouseName"
                  :value="item.warehouseId"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="入库批次"
          width="220"
          label-class-name="required-th"
        >
          <template slot-scope="scope">
            <div v-if="isEdit">
              <div
                class="lot-sty"
                v-for="(item, i) in scope.row.outLots"
                :key="i"
              >
                <span class="ell">
                  {{ item.lotNo + '(' + item.num + ')' }}
                </span>
              </div>
            </div>
            <div v-else>
              <div
                v-if="
                  !scope.row.outLots.length && scope.row.warehouseInBatch === 1
                "
                class="lot-sty no"
              >
                -
                <i
                  class="el-icon-circle-plus-outline ml5"
                  @click="openStorage(scope.$index)"
                ></i>
              </div>
              <div v-else class="mt5 mb5">
                <div
                  class="lot-sty"
                  v-for="(item, i) in scope.row.outLots"
                  :key="i"
                >
                  <span class="ell">
                    {{ item.lotNo + '(' + item.num + ')' }}
                  </span>
                  <i
                    v-show="i === 0 && scope.row.warehouseInBatch === 1"
                    class="el-icon-circle-plus-outline"
                    @click="openStorage(scope.$index)"
                  ></i>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <h4>备注</h4>
      <el-input
        type="textarea"
        maxlength="500"
        show-word-limit
        :rows="2"
        v-model="form.remark"
        placeholder="请输入内容，字数500字以内"
        clearable
      ></el-input>

      <h4>附件</h4>
      <ErpUpload
        ref="ErpUpload"
        :uploadParams="uploadParams"
        @uploadList="uploadList"
        @initUpload="initUpload"
      ></ErpUpload>
    </el-form>

    <div class="oper-btn">
      <el-button @click="Cancel" type="default">取消</el-button>
      <el-button @click="Submit" type="primary" :loading="loading">
        确认
      </el-button>
    </div>

    <!-- 批次选择 -->
    <LotBatchSel ref="lotBatchSel" @lotlist-set="setLocList"></LotBatchSel>
  </div>
</template>

<script>
  import { InboundSheet } from '@/core'
  import { OutProcessing } from '@/core/interactors/purchase/outsourceProcess'
  import Dicts from './dict/index'
  import FuzzyMatching from '@/components/FuzzyMatching'
  import LotBatchSel from '@/components/lot-batch-sel/index.vue'
  import { mapGetters } from 'vuex'
  export default {
    components: {
      FuzzyMatching,
      LotBatchSel,
    },
    data() {
      return {
        processOrderId: '', // 加工单id
        // defaultOutWarehouseId: '', // 加工配置的默认出库仓库
        form: {
          salesOrderId: '', // 销售订单Id
          salesOrderCode: '', // 销售单号
          supplierId: '', // 供应商ID
          supplierName: '', // 供应商名称
          inWarehouseId: '', // 成品入库仓库ID
          inWarehouseName: '', // 成品入库仓库
          remark: '', // 备注说明
          annexFiles: '', // 附件
          businessUserId: '', // 客户代表Id
          businessUserName: '', // 客户代表名称
          businessUserNameEn: '', // 客户代表英文名称
          list: [], // 加工产品与物料信息
        },
        amount: 0, // 生产总数
        attritionRate: 0, // 损耗率
        lotIndex: 0, // 批次索引
        defaultSupplierList: [], // 供应商下拉列表
        warehouses: [], // 成品入库下拉列表
        printingWaySelect: Dicts.printingWaySelect, // 印刷方式字典
        whetherList: Dicts.whetherList, // 是否字典
        defaultOrderCode: {}, // 销售单回显
        rules: {
          supplierId: [
            { required: true, message: '请输入', trigger: 'change' },
          ],
          inWarehouseId: [
            { required: true, message: '请输入', trigger: 'blur' },
          ],
        },
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        isEdit: false,
        isDisOrderCode: false,
        productVisible: false,
        storageVisible: false,
        multiSetVisible: false,
        loading: false,
        printWayList: [], // 印刷方式集合
        curDefaultOutWarehouseId: '', // 供应商change，保存defaultOutWarehouseId
        isAttritionRateSet: false, // 是否是批量设置损耗率，默认为false
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    filters: {},
    created() {
      const query = this.$route.query

      this.getDicts()
      this.processOrderId = query?.id
      if (this.processOrderId) {
        this.isEdit = true
        this.getEdit()
      }
      // 委外加工入口
      if (query?.purchasepros) {
        this.isDisOrderCode = true
        this.getPurchaseDetail(query.purchasepros)
      }
    },
    methods: {
      // 获取字典项
      async getDicts() {
        const res = await InboundSheet.findWarehouseList({
          hasCloudWarehouse: 1,
        })

        if (res && res.code === '000000') {
          this.warehouses = res.data
          !this.isEdit && this.getConfig()
        }
      },

      // 获取配置项
      async getConfig() {
        const res = await OutProcessing.getOutProcessingConfig()
        if (res && res.code === '000000') {
          const data = res.data
          // 供应商下拉列表
          this.defaultSupplierList = data?.processConfigSupplierVOS || []
          // 默认赋值入库出库仓库
          // if (data?.processConfigVO) {
          //   this.form.inWarehouseId = data.processConfigVO.defaultInWarehouseId
          //   this.form.inWarehouseName = this.warehouses.find(
          //     (item) => item.warehouseId === this.form.inWarehouseId
          //   )?.name
          //   this.defaultOutWarehouseId =
          //     data.processConfigVO.defaultOutWarehouseId
          // }
        }
      },

      // 编辑获取详情
      async getEdit() {
        const res = await OutProcessing.processGetDetail(this.processOrderId)

        if (res && res.code === '000000') {
          this.form = { ...res.data }
          // ??? 更新需要转成warehouseId，warehouseName接收
          this.form.list = res.data?.processProductVOList?.map((item) => {
            return {
              ...item,
              warehouseId: item.warehouseOutId,
              warehouseName: item.warehouseOutName,
              rendingsUrl: item.productImage,
            }
          })
          console.log('编辑详情', JSON.parse(JSON.stringify(this.form)))
          this.form?.annexFiles && this.initUpload(this.form.annexFiles)
          // 销售单回显
          this.defaultOrderCode = {
            salesOrderId: this.form.salesOrderId,
            salesOrderCode: this.form.salesOrderCode,
          }
          this.calcAmount(-1)
        }
      },

      // 委外加工入口获取详情
      getPurchaseDetail(str) {
        const _arr = JSON.parse(decodeURI(str))
        const { salesOrderId, salesOrderNumber } = _arr[0]
        // 批量操作参数处理
        let [skus, orderProductUniqueIds] = [[], []]
        _arr.forEach((item) => {
          skus.push(item.sku)
          orderProductUniqueIds.push(item.orderProductUniqueId || '')
        })

        this.form.salesOrderId = salesOrderId
        this.form.salesOrderCode = salesOrderNumber
        // 销售单回显
        setTimeout(() => {
          this.defaultOrderCode = {
            salesOrderId: salesOrderId,
            salesOrderCode: salesOrderNumber,
          }
        }, 400)
        // 带出加工产品
        this.fuzzyChoose(
          [
            {
              order_id: salesOrderId,
              order_code: salesOrderNumber,
              skus: skus,
              orderProductUniqueIds: orderProductUniqueIds,
            },
          ],
          true
        )
      },

      /**
       * 选择销售单号
       * @param {arr} 入参
       * @param {bool} 入口来源（true: 委外加工入口）
       */
      async fuzzyChoose(arr, bool) {
        const { order_id, order_code, skus, orderProductUniqueIds } = arr[0]

        if (order_code) {
          this.form.salesOrderId = order_id
          this.form.salesOrderCode = order_code
          const res = await OutProcessing.orderGetDetail(order_id)

          if (res && res.code === '000000') {
            if (res.data?.orderProductVOS.length) {
              const data = res.data

              this.form.businessUserId = data.businessId
              this.form.businessUserName = data.businessName
              this.form.businessUserNameEn = data.businessNameEn
              this.form.list = data.orderProductVOS.map((item) => {
                return {
                  fromBusinessUserId: item.fromBusinessId, // 订单支持id
                  fromBusinessUserName: item.fromBusinessName, // 订单支持名称
                  expectDeliveryDate: '', // 期望交期
                  innerRemark: data.remark, // 内部备注
                  loseRatio: 1, // 规定损耗率
                  orderProductUniqueId: item.orderProductUniqueId, // 销售产品唯一ID
                  packaging: '', // 包装要求
                  printColor: '', // 印刷颜色
                  printDesc: item.printingEffect, // 印刷描述
                  printSize: '', // 印刷尺寸
                  printWay: item.printingWay, // 印刷方式
                  processNum: item.amount, // 加工数量
                  productCn: item.productCn, // 中文名
                  productCode: item.productCode, // 产品编码
                  productEn: item.productEn, // 产品英文名称
                  productId: item.productId, // 产品ID
                  productImage: item.rendingsUrl, // 效果图
                  rendingsUrl: item.rendingsUrl,
                  imgMain: item.imgMain,
                  // productImage: item.printingWay === '无需印刷' ? item.rendingsUrl || item.imgMain : item.rendingsUrl, // 效果图
                  productMaterial: item.material, // 材质
                  productSpecs: item.specification, // 产品颜色
                  productType: item.standardCustomized, // 产品类型
                  productUnit: item.productUnit, // 单位
                  sampleNum: 0, // 留样数量
                  skuId: item.sku, // SKU
                  urgent: 0, // 是否加急（0否，1是）
                  productSize: this.getProductSize(
                    item.sku,
                    item.productSkuVOS
                  ), // 产品尺寸
                  warehouseId: '', // 出库仓库ID
                  warehouseName: '', // 出库仓库名
                  applyNum: '', // 申请出库数量
                  outLots: [], // 出库批次
                  warehouses: [], // 可用库存
                  productExtend: item.productExtend // 产品扩展信息：计划装运日期、客户交期、是否Firm 交期
                }
              })
              console.log('销售单号change',JSON.parse(JSON.stringify(this.form)))

              bool &&
                (this.form.list = this.form.list.filter(
                  (item) =>
                    skus.includes(item.skuId) &&
                    orderProductUniqueIds.includes(item.orderProductUniqueId)
                ))

              this.calcAmount(-1)
              // 销售单号change，需要触发印刷方式change回调，回显供应商
              this.handlePrintWayChange()
            }
          }
        }
      },

      /**
       * 获取产品尺寸
       * @param {sku} sku
       * @param {_arr} 规格集合
       */
      getProductSize(sku, _arr) {
        if (_arr?.length) {
          // length=1单规格，>1多规格
          if (_arr.length === 1) {
            return this.handleProductSize(_arr[0], _arr[0].sizeUnit)
          } else {
            for (let i = 0; i < _arr.length; i++) {
              if (_arr[i].skuId === sku) {
                return this.handleProductSize(_arr[i], _arr[i].sizeUnit)
              }
            }
          }
        } else {
          return '--'
        }
      },

      /**
       * 产品尺寸数据处理
       * @param {_obj} 规格对象
       * @param {unit} 规格单位
       */
      handleProductSize(_obj, unit) {
        if (
          !_obj.productLengthCm &&
          !_obj.productWidthCm &&
          !_obj.productHeightCm
        ) {
          return '--'
        } else {
          if (unit === 'CM') {
            return (
              (_obj.productLengthCm
                ? (Number(_obj.productLengthCm) * 0.3937).toFixed(2)
                : '--') +
              '"x' +
              (_obj.productWidthCm
                ? (Number(_obj.productWidthCm) * 0.3937).toFixed(2)
                : '--') +
              '"x' +
              (_obj.productHeightCm
                ? (Number(_obj.productHeightCm) * 0.3937).toFixed(2)
                : '--') +
              '"'
            )
          } else if (unit === 'FT') {
            return (
              (_obj.productLengthCm
                ? (Number(_obj.productLengthCm) * 12).toFixed(2)
                : '--') +
              '"x' +
              (_obj.productWidthCm
                ? (Number(_obj.productWidthCm) * 12).toFixed(2)
                : '--') +
              '"x' +
              (_obj.productHeightCm
                ? (Number(_obj.productHeightCm) * 12).toFixed(2)
                : '--') +
              '"'
            )
          } else {
            return (
              (_obj.productLengthCm ? _obj.productLengthCm : '--') +
              '"x' +
              (_obj.productWidthCm ? _obj.productWidthCm : '--') +
              '"x' +
              (_obj.productHeightCm ? _obj.productHeightCm : '--') +
              '"'
            )
          }
        }
      },

      // 生产总数
      calcAmount(i, type) {
        if (type === 'loseRatio') {
          this.form.list[i].loseRatio > 100 &&
            (this.form.list[i].loseRatio = 100)
        }
        this.amount = this.form.list.reduce(
          (p, v) =>
            p + Number(v['processNum'] || 0) + Number(v['sampleNum'] || 0),
          0
        )
        // i >= 0 && this.calcApplyNum(i)
        !this.isEdit && this.calcApplyNum(i, this.curDefaultOutWarehouseId)
      },

      // 选择供应商
      async supplierChange(val) {
        this.form.supplierName = this.defaultSupplierList?.find(
          (item) => item.supplierId === val
        )?.supplierName
        try {
          const { code, data } = await OutProcessing.detaillDefApi(val)
          if (code === '000000') {
            // 回显成品入库
            this.form.inWarehouseId = data.defaultInWarehouseId
            this.form.inWarehouseName = data.defaultInWarehouseName
            // 重新回显出库仓库和入库批次
            this.calcApplyNum(-1, data.defaultOutWarehouseId)
            // 保存当前供应商所对应的defaultOutWarehouseId
            this.curDefaultOutWarehouseId = data.defaultOutWarehouseId
          }
        } catch (e) {
          console.log(e)
        }
      },

      // 选择成品入库
      inWarehouseChange(val) {
        this.form.inWarehouseName = this.warehouses.find(
          (item) => item.warehouseId === val
        ).name
      },

      // 批量设置损耗率
      attritionRateSet() {
        this.form.list.forEach((item) => (item.loseRatio = this.attritionRate))
        this.multiSetVisible = false
        this.calcApplyNum(-1)
        this.isAttritionRateSet = true // 当前为批量设置损耗率，在getRecommendWarehouse方法中，不要影响出库仓库
      },

      // 申请出库数量 defaultOutWarehouseId：从detailDef接口获取
      calcApplyNum(i, defaultOutWarehouseId) {
        const list = this.form.list
        let _arr = []

        if (i >= 0) {
          // 单个设置损耗率
          if (!!list[i].loseRatio || list[i].loseRatio === 0) {
            const amount =
              Number(list[i].processNum || 0) + Number(list[i].sampleNum || 0)

            // 赋值申请出库数量
            this.form.list[i].applyNum = Math.ceil(
              // amount * (1 + 0.01 * (list[i].loseRatio || 0))
              amount + amount * 0.01 * (list[i].loseRatio || 0)
            )
            // 获取调用可用库存入库批次接口参数
            _arr.push({
              defaultOutWarehouseId,
              num: list[i].applyNum,
              orderProductUniqueId: list[i].orderProductUniqueId,
              salesOrderNumber: this.form.salesOrderCode,
              skuId: list[i].skuId,
              tenantId: this.userInfo.tenantId,
            })
          }
        } else {
          // 批量设置损耗率
          for (let n = 0; n < list.length; n++) {
            // 订单数量及损耗率有值计算，损耗率可以为0
            if (!!list[n].loseRatio || list[n].loseRatio === 0) {
              const amount =
                Number(list[n].processNum || 0) + Number(list[n].sampleNum || 0)

              // 赋值申请出库数量
              this.form.list[n].applyNum = Math.ceil(
                // amount * (1 + 0.01 * (list[n].loseRatio || 0))
                amount + amount * 0.01 * (list[n].loseRatio || 0)
              )
              // 获取调用可用库存入库批次接口参数
              _arr.push({
                defaultOutWarehouseId,
                num: list[n].applyNum,
                orderProductUniqueId: list[n].orderProductUniqueId,
                salesOrderNumber: this.form.salesOrderCode,
                skuId: list[n].skuId,
                tenantId: this.userInfo.tenantId,
              })
            }
          }
        }

        if (_arr.length) {
          this.getDefaultRecommend(_arr, i)
        }
      },

      /**
       * 获取可用库存及入库批次
       * @param {params} 入参
       * @param {i} 产品索引 -1：批量
       */
      async getDefaultRecommend(params, i) {
        const res = await OutProcessing.processOutStockAndDefaultRecommend(
          params
        )

        if (res && res.code === '000000') {
          const _arr = res.data
          let list = this.form.list

          if (_arr.length) {
            if (i >= 0) {
              list[i].warehouses = _arr[0].warehouses
              // 设置损耗率重新计算入库批次
              list[i].warehouseId &&
                this.warehouseChange(list[i].warehouseId, i)
            } else {
              for (let n = 0; n < list.length; n++) {
                list[n].warehouses = _arr[n].warehouses
                const _list = list[n].warehouses.filter((m) => m.isRecommend)
                const _warehouseId = _list.length ? list[n].warehouseId : ''
                // 批量设置损耗率重新计算入库批次
                _warehouseId && this.warehouseChange(_warehouseId, n)
              }
            }
            this.getRecommendWarehouse(i)
          }
        }
      },

      // 获取推荐的库存和批次
      getRecommendWarehouse(index) {
        // index为-1时：销售单号change，印刷方式change会影响供应商change，包括供应商自己的change，此时需要重置所有产品的出库库存和入库批次
        // index为0 1 2 ... 时：订单数量、留样数量、损耗率发生change时，只需要更新自己的出库库存和入库批次
        if (index === -1) {
          let list = this.form.list

          for (let i = 0; i < list.length; i++) {
            // 批量设置损耗率时，只影响入库批次，不要影响出库仓库
            if(!this.isAttritionRateSet) {
              const _arr = list[i].warehouses.filter((item) => item.isRecommend)
              // !list[i].warehouseId &&
              // 如果warehouses中isRecommend的值是true，至少一条，就调接口回显出库仓库和入库批次
              if (_arr.length) {
                list[i].warehouseId = _arr[0].warehouseId
                list[i].warehouseName = _arr[0].warehouseName
                this.warehouseChange(list[i].warehouseId, i)
              } else {
                // warehouses中isRecommend的值都是false，不调接口，直接清空出库仓库和入库批次
                list[i].warehouseId = ''
                list[i].warehouseName = ''
                this.form.list[i].outLots = [] // 清空入库批次
              }
            }
          }
        }
      },

      // 选择出库仓库
      async warehouseChange(id, i) {
        const list = this.form.list
        const obj =
          list[i].warehouses.find((item) => item.warehouseId === id) || {}
        const params = {
          warehouseId: id,
          skuId: list[i].skuId,
          num: list[i].applyNum,
          salesOrderNumber: this.form.salesOrderCode,
          orderProductUniqueId: list[i].orderProductUniqueId,
          tenantId: this.userInfo.tenantId,
          recommendLotDTOs: this.getRecommendLots(i),
          processOut: true,
        }
        const res = await OutProcessing.lotRecommend(params)

        this.form.list[i].warehouseName = obj.warehouseName
        // 赋值入库批次
        if (res && res.code === '000000') {
          // 获取是否可修改入库批次
          const _recommendRes = await OutProcessing.getStockCostConfigureVO(id)
          const warehouseInBatch = _recommendRes?.data.warehouseInBatch

          this.form.list[i].outLots = res.data
          this.$set(
            this.form.list,
            i,
            Object.assign(
              {},
              {
                ...this.form.list[i],
              },
              {
                warehouseInBatch: warehouseInBatch,
              }
            )
          )
          this.isAttritionRateSet = false
        }
      },

      /**
       * 获取推荐批次列表
       * @param {*} i 选中的出库索引
       */
      getRecommendLots(i) {
        let _lots = []
        const _list = this.form.list

        for (let n = 0; n < _list.length; n++) {
          const _childrens = _list[n].outLots
          if (i != n && _childrens.length) {
            for (let m = 0; m < _childrens.length; m++) {
              _lots.push({
                num: _childrens[m].num, // 推荐数量
                lotNo: _childrens[m].lotNo, // 批次号
                stockLotId: _childrens[m].stockLotId, // 推荐批次
              })
            }
          }
        }

        return _lots
      },

      // 确认
      Submit() {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            if (this.form.list.length === 0) {
              return this.$message.warning('请选择一条产品！')
            }
            if (this.hasOutLots()) {
              return this.$message.warning('入库批次必填！')
            }

            const params = this.dataProcessing()
            let res
            if (this.isEdit) {
              res = await OutProcessing.processUpdatePO(params)
            } else {
              res = await OutProcessing.processInsertPO(params)
            }

            this.loading = true
            if (res && res.code === '000000') {
              this.$message.success(
                `${this.isEdit ? '编辑成功！' : '新增成功！'}`
              )
              this.$router.push('/purchase/outsourceProcess/outsource-process')
              this.loading = false
            } else {
              this.loading = false
            }
          }
        })
      },

      // 取消
      Cancel() {
        this.goBack()
        this.form = this.$options.data().form
      },

      // 入库批次必填校验
      hasOutLots() {
        let [list, flag] = [this.form.list, false]

        for (let i = 0; i < list.length; i++) {
          if (!list[i].outLots?.length) {
            flag = true
            break
          }
        }
        return flag
      },

      // 提交参数处理
      dataProcessing() {
        this.form.processProductDTOList = this.form.list

        return {
          ...this.form,
          processOrderId: this.isEdit ? this.processOrderId : '',
        }
      },

      /**
       * 选择入库批次
       * @param {i} 批次索引
       */
      openStorage(i) {
        const list = this.form.list[i]
        const outLots = list.outLots.map((item) => {
          return {
            ...item,
            lotNumber: item.num,
          }
        })

        this.lotIndex = i
        this.$refs.lotBatchSel.show({
          list: outLots,
          sku: list.skuId,
          orderCode: this.form.salesOrderCode,
          productName: list.productCn,
          warehouseId: list.warehouseId,
          applyNum: list.applyNum,
          orderProductUniqueId: list.orderProductUniqueId,
          processOut: true,
        })
      },

      // 获取选中的入库批次
      setLocList(_arr) {
        let list = this.form.list[this.lotIndex]

        list.outLots = []
        for (let i = 0; i < _arr.length; i++) {
          _arr[i].num = _arr[i].lotNumber
          list.outLots = _arr
        }
      },

      // 删除产品
      del(i) {
        if (this.form.list.length === 1) {
          this.$message.warning('至少保留一条产品！')
        } else {
          this.$confirm(`是否确认删除?`, '提示', { type: 'warning' }).then(
            () => {
              this.form.list.splice(i, 1)
              // 删除后，需要根据最新的印刷方式集合，回显供应商
              this.handlePrintWayChange(this.form.list)
            }
          )
        }
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.annexFiles = JSON.stringify(fileList)
      },

      // 回显附件
      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },

      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },
      // 印刷方式change，回显供应商
      async handlePrintWayChange(val) {
        if (this.isEdit) return console.log('编辑不需要回显供应商')
        if (Array.isArray(val)) {
          let curList = val.map((n) => n.printWay)
          curList = [...new Set(curList)] // 删除后的印刷方式集合
          if (this.printWayList.length === curList.length) {
            return console.log('印刷方式集合未发生改变')
          }
        }
        try {
          let list = this.form.list.map((n) => n.printWay)
          if (list.length) {
            list = [...new Set(list)]
            this.printWayList = list
            const { code, data } = await OutProcessing.listPrintingApi(list)
            if (code === '000000') {
              // 查询到唯一供应商时，需要回显供应商
              if (Array.isArray(data) && data.length === 1) {
                this.form.supplierId = data[0]?.supplierId
                this.form.supplierName = data[0]?.supplierName
                this.supplierChange(data[0]?.supplierId)
              } else {
                this.form.supplierId = ''
                this.form.supplierName = ''
              }
            } else {
              this.form.supplierId = ''
              this.form.supplierName = ''
            }
          }
        } catch (e) {
          this.form.supplierId = ''
          this.form.supplierName = ''
          console.log(e)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .el-form {
    padding: 0 10px;
    height: calc(100vh - 180px);
    overflow: auto;
    h4:first-child {
      margin: 0 0 10px;
    }
    .label-text {
      margin: 10px 0;
      text-align: left;
      color: #666;
    }
    .multi-icon {
      cursor: pointer;
      margin-left: 10px;
      color: #409eff;
      font-size: 16px;
    }
    .lot-sty {
      display: flex;
      justify-content: space-between;
      span {
        width: 180px;
        text-align: left;
        display: block;
      }
      i {
        font-size: 24px;
        cursor: pointer;
        color: #409eff;
      }
    }
    .lot-sty.no {
      justify-content: start;
    }
  }
  .oper-btn {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: white;
    padding: 10px 0;
  }
  ::v-deep {
    .el-select,
    .el-date-editor {
      width: 100%;
    }
    .el-table {
      .el-date-editor {
        width: 100%;
      }
    }
    .el-table__footer-wrapper {
      .has-gutter {
        height: 40px;
        font-family: 'PingFang Bold';
        .required-th::before {
          display: none;
        }
        td {
          text-align: center;
        }
      }
    }
  }
</style>
